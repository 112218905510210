import React from 'react'
// import {Link} from "gatsby"
import Layout from "../Components/reusable/Layout"
import Banner from "../Components/reusable/Banner"
import QualityBanner from "../Images/background/QualityBanner.jpg"
import iso from "../Images/Certificates/iso.jpg"
import iatf from "../Images/Certificates/iatf.jpg"
import zed from "../Images/Certificates/qcized.jpg"

function Quality() {
    return (
        <Layout>
         <Banner title="Quality Policy" />   
    <div className="section">
		<div className="content-wrap">
			<div className="container">


<div className="row mb-5"  id="qualityPolicy">
    <div className="col-sm-12 col-md-4">
        <img src={QualityBanner} alt="" className="img-fluid"  />	
    </div>
    <div className="col-sm-12 col-md-8">
        <h4 className="text-primary  uk21">
        Quality Assurance :-
        </h4>
        <div>
            <p> Quality is tested on several parameters to ensure that clients get the best features in the components delivered by us.</p>	
            <p> <i className="fas fa-angle-double-right"></i> Separate QC department.</p>         
            <p> <i className="fas fa-angle-double-right"></i> In line final product inspection.</p>         
            <p> <i className="fas fa-angle-double-right"></i> Display of charts and drawings at various workstations and machines.</p>         
            <p> <i className="fas fa-angle-double-right"></i> Collection/ accumulation of data on daily basis.</p>         
            <p> <i className="fas fa-angle-double-right"></i> Process calibration/ equipment setting using past data and statistical tools.</p>         
            <p> <i className="fas fa-angle-double-right"></i> Strict adherence to customer drawings and needs.</p>         
            <p> <i className="fas fa-angle-double-right"></i> In addition, we have a fully equipped Testing Facility that boasts of latest instruments used for the following :-</p>         
            <ul>           
                <li>Heat Treatment checking.</li>           
                <li>Run out checking.</li>           
                <li>Profile checking.</li>         
            </ul>                
           
        </div>
            
    </div>
</div>




<div className="row my-5">


{/* <div className="col-sm-12 col-md-12"> */}

    {/* <div id="carousel-services" className="owl-carousel owl-theme owl-dark"> */}
        
        <div className="col-md-4 ">
            <div className="rs-icon-info-1" style={{paddingBottom: "3rem"}}>
                <div className="info-icon">
                    <i className="fas fa-building"></i>
                </div>
                <div className="info-text">
                    <h4 className="text-black mb-2">ISO Certificate</h4>
                    <p>ISO certification certifies that a management system, manufacturing process,
                         service, or documentation procedure has all the requirements for standardization and quality assurance.</p>
                    <a href={iso} className="btn btn-secondary">view certificate</a>
                </div>
            </div>
            </div>


        <div className="col-md-4 ">
        <div className="rs-icon-info-1" style={{paddingBottom: "3rem"}}>
            <div className="info-icon">
                <i className="fas fa-comments"></i>
            </div>
            <div className="info-text">
                <h4 className="text-black mb-2">IATF 16949</h4>
                <p>IATF 16949:2016 (1st edition) represents an innovative document, given the strong orientation to the customer, with inclusion of a number of consolidated previous customer specific requirements.</p>
                <a href={iatf} className="btn btn-secondary">view certificate</a>
            </div>
        </div>
        </div>

        <div className="col-md-4">
        <div className="rs-icon-info-1">
            <div className="info-icon">
                <i className="fas fa-cube"></i>
            </div>
            <div className="info-text">
                <h4 className="text-black mb-2">QCI ZED</h4>
                <p>We should manufacture goods in such a way that they carry zero defect and that our exported goods are never returned to us. We should manufacture goods with zero effect that they should not have a negative impact on the environment</p>
                <a href={zed} className="btn btn-secondary">view certificate</a>
            </div>
        </div>
        </div>
        
    


    {/* </div> */}

{/* </div> */}



</div>


            </div>
        </div>
    </div>
    
        </Layout>
    )
}

export default Quality
